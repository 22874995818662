h1 {
	margin-top: 20px;
}

.card-title {
	margin-bottom: 0;
}

.author {
	font-size: 14px;
}

.timestamp {
	font-size: 12px;
	color: gray;
	margin-bottom: 10px;
}
